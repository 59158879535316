import {qspost} from '@/plugins/axios'

/*
 * 集团列表
 * @constructor jack
 * */
export const groupList = (page, limit, searchName) => qspost('/index/Group/groupList', {page, limit, searchName})

/*
 * 添加、修改集团
 * @constructor jack
 * */
export const addOrEditGroup = (id,name) => qspost('/index/Group/addOrEditGroup', {id,name})

/*
 * 删除集团
 * @constructor jack
 * */
export const delGroup = (id) => qspost('/index/Group/delGroup', {id})

/*
 * 集团绑定公司
 * @constructor jack
 * */
export const groupAddOrg = (group_id, org_id_str) => qspost('/index/Group/groupAddOrg', {group_id, org_id_str})

/*
 * 集团解除绑定公司
 * @constructor jack
 * */
export const groupRemoveOrg = (group_id, org_id_str) => qspost('/index/Group/groupRemoveOrg', {group_id, org_id_str})
