<template>
  <div class="wrapper padding-lr-sm">
    <div class="flex align-center justify-between margin-bottom-sm">
      <div class="flex align-center ">
        <el-input size="small" v-model="keyword" placeholder="请输入集团名称"
                  class=" margin-right-sm search-input"></el-input>
        <el-button size="small" type="primary" @click="hanldSearch">搜索</el-button>
      </div>

      <el-button size="small" type="primary" round @click="orgDialog = true">创建集团</el-button>

    </div>
    <el-table
        :data="groupList"
        header-row-class-name="table-header"
        row-class-name="table-row"
        alig="center"
        :cell-style="{'text-align':'center'}"
        border
        style="width: 100%"
    >
      <el-table-column width="70" type="index" align="center" label="序号"></el-table-column>
      <el-table-column label="集团名称" prop="name"></el-table-column>
      <el-table-column label="公司数量" prop="bind_org_count"></el-table-column>
      <el-table-column label="创建时间" prop="create_time"></el-table-column>
      <el-table-column width="250" label="操作">
        <template slot-scope="scope">
          <div class="table-operate">
            <div class="flex align-center justify-between margin-bottom-xs">
              <el-button size="small" type="primary" @click="editItem(scope.row)">编辑</el-button>
              <el-button size="small" type="primary" @click="orgItem(scope.row)">公司管理
              </el-button>
              <el-button size="small" type="danger" @click="deleteItem(scope.row.id)">删除</el-button>
            </div>

          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页-->
    <el-pagination
        v-if="groupList.length > 0"
        class="page-all"
        background
        @current-change="getList"
        :current-page.sync="page"
        :page-size="limit"
        layout="prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>
    <!--新增修改机构信息-->
    <el-drawer
        title="集团信息设置"
        :visible.sync="orgDialog"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        :before-close="cancelDialog"
    >
      <div class="padding-lr">
        <el-form :model="groupForm"
                 label-width="80px"
                 :rules="rules" ref="groupForm" class="add-form">
          <el-form-item label="集团名称" prop="name">
            <el-input v-model="groupForm.name" placeholder="请填写集团名称"></el-input>
          </el-form-item>
          <el-form-item label-width="0" class="flex justify-around">
            <el-button class="submit-btn" type="primary" @click="submitForm('groupForm')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-drawer>

    <!--管理员管理机构-->
    <el-dialog
        title="机构管理"
        :visible.sync="dialogVisible"

        width="625px"
        :before-close="closeDialog">


      <el-transfer
          :titles="['可选择机构', '已选择机构']"
          v-model="chooseList"
          :props="{key: 'id',label: 'mingcheng'}"
          :data="orgList">
      </el-transfer>


      <div slot="footer" class="flex align-center justify-around">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitOrgEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import rules from "@/utils/rules";
import {addOrEditGroup, delGroup, groupAddOrg, groupList, groupRemoveOrg} from "../../../api/group";
import {orgList} from "../../../api/org";

export default {
  name: "groupList",
  data() {
    return {
      keyword: '',
      page: 1,
      limit: 10,
      total: 0,
      groupList: [],
      orgDialog: false,
      groupForm: {
        name: '',
      },
      rules: {
        name: rules.mustInput,
      },
      setId: 0,
      setItem: {},
      dialogVisible: false,
      orgList: [],
      chooseList: [],
      chooseListDel: [],

    }
  },
  created() {
    this.$emit("setIndex", this.$route.path, "集团列表", false);
    this.getList()
  },
  components: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await addOrEditGroup(
          this.setId ?this.setId :'',
          this.groupForm.name,
      )

      if (_state.status === 200) {
        this.cancelDialog()
      } else {
        this.$message.success(_state.info)
      }
    },
    //  编辑
    editItem(obj) {
      this.groupForm = {
        name: obj.name,
      }
      this.setId = obj.id;
      this.orgDialog = true;
    },
    //  员工信息编辑后回调
    cancelDialog() {
      this.setId = 0;
      this.setItem = {};
      this.groupForm.name = ''
      this.$refs['groupForm'].resetFields();
      this.$refs['groupForm'].clearValidate();
      this.orgDialog = false;
      this.getList()
    },
    //  删除
    deleteItem(id) {
      this.$confirm('您确定要删除该集团信息吗？', "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        cancelButtonClass: "msg-btn",
        confirmButtonClass: "msg-btn",
        center: true
      }).then(() => {
        this.sureDelete(id);
      });
    },
    async sureDelete(id) {
      let _state = await delGroup(id);
      if (this.groupList.length === 1 && this.page > 1) {
        this.page--;
      }
      await this.getList();
      this.$message.success(_state.info);
    },
    //  公司管理
    orgItem(item) {
      this.setId = item.id
      this.setitem = item
      this.getOrgList()
      this.dialogVisible = true
    },
    //  获取全部公司列表
    async getOrgList() {
      const res = await orgList(1,99999,'')
      let _arr = []
      res.data.map(item => {
        if (item.group_id === 0 || item.group_id === this.setId) {
         _arr.push(item)
        }
        if(item.group_id === this.setId){
          this.chooseList.push(Number(item.id))
          this.chooseListDel.push(Number(item.id))
        }
      })
      this.orgList = _arr
    },
    //  关闭公司管理弹框
    closeDialog() {
      this.setId = 0
      this.chooseList = []
      this.chooseListDel = []
      this.dialogVisible = false
    },
    async submitOrgEdit() {
      let _del = []
      if(this.chooseListDel.length>0){
        this.chooseListDel.map(item=>{
          if(this.chooseList.indexOf(item) === -1){
            _del.push(item)
          }
        })
      }

      if(_del.length>0){
        await groupRemoveOrg(this.setId, _del.join(','))
      }
      if (this.chooseList.length > 0 ){
        let _add = []
        this.chooseList.map(item=>{
          if(this.chooseListDel.indexOf(item) === -1){
            _add.push(item)
          }
        })
       if(_add.length>0){
         await groupAddOrg(this.setId, _add.join(','))
         await this.closeDialog()
         await this.getList()
       }else{
         await this.closeDialog()
         await this.getList()
       }
      }else{
        await this.closeDialog()
        await this.getList()
      }

    },
    //  搜索
    hanldSearch() {
      this.page = 1
      this.getList()
    },
    //  获取各集团列表
    async getList() {
      const res = await groupList(this.page, this.limit, this.keyword)
      this.groupList = res.data
      this.total = res.total_number
    }
  },
  filters: {},
  watch: {
    orgDialog() {
      if (!this.orgDialog) this.cancelDialog()
    }
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  .search-input {
    width: 300px;
  }
}
</style>
