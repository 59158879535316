import {qspost} from '@/plugins/axios'

/*
 * 机构列表 - 超管看全部、子管理看部分 由接口判断
 * @constructor jack
 * */
export const orgList = (page, limit, searchName) => qspost('/index/Org/orgList', {page, limit, searchName})

/*
 * 机构详情
 * @constructor jack
 * */
export const orgShow = (id) => qspost('/index/Org/orgShow', {id})

/*
 * 添加或修改机构
 * @constructor jack
 * */
export const addOrEditOrg = (id, mingcheng,jigouxingzhi, faren,lianxiren, dianhua,youxiang, dizhi) => qspost('/index/Org/addOrEditOrg', {
    id, mingcheng,jigouxingzhi, faren,lianxiren, dianhua,youxiang, dizhi
})

/*
 * 删除机构
 * @constructor jack
 * */
export const delOrg = (id) => qspost('/index/Org/delOrg', {id})

